<template>
  <div class="upinfo-style">
    <div class="box1a start-center">
      <van-icon color="#000" name="arrow-left" size="0.6rem" @click="back" />
      <img :src="img" alt="" class="box1-i1" />
      <div class="box1-b1">
        <div class="box1-b1-t1 break" v-if="collection_id != 0" @click="goAhead(`/set?id=${collection_id}&chainType=${chainType}`)">{{collectionname}}</div>
        <div class="box1-b1-t2 ">{{title}}#{{tokenId}}</div>
      </div>
    </div>
    <div class="box2">
      <div class="box2-t1 between-center">
        <div>{{$t('text.t127')}}</div>
        <div>{{ amount }}</div>
      </div>
      <div class="box2-b1 start-center">
        <div class="box2-b1-l center-center">
          <img src="@/assets/img/nft.png" alt="" class="box2-b1-l-i1" />
          <div class="box2-b1-l-t1">NFT</div>
        </div>
        <div class="box2-b1-r">
          <input type="number" class="box2-b1-r-t1" v-model="num" :placeholder="$t('text.t128')" />
        </div>
      </div>
      <div class="box2-t1">{{$t('text.t115')}}</div>
      <div class="box2-b1 start-center">
        <div class="box2-b1-l center-center">
          <img :src="chainId=='0x2d20'?$concatImg('img44'):$concatImg('img43')" alt="" class="box2-b1-l-i1" />
          <div class="box2-b1-l-t1">USDT</div>
        </div>
        <div class="box2-b1-r">
          <input type="number" class="box2-b1-r-t1" v-model="price" :placeholder="$t('text.t129')" />
        </div>
      </div>
      <!-- <div class="box2-b2">≈0 OMT</div> -->
      <div class="box2-b3 box2-b3-mb15">{{$t('text.t130')}}</div>
      <div class="box2-b3 box2-b3-mb24">{{config.market_fee}}%</div>
      <div v-loading="isloading" class="box2-b4 center-center" @click="upshop">{{$t('text.t36')}}</div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import BigNumber from "bignumber.js";
export default {
  components: {
  },
  data() {
    return {
      collection_id:'',
      collectionname:'',
      title:'',
      tokenId:'',
      nft_id:'',
      img:'',
      isloading:false,
      price:'',
      num:'',
      type:'',
      chainType:'',
      amount:'',
      chain:'',
      tmsg:''
    };
  },
  created(){
    const {nft_id,chain} = this.$route.query
    this.nft_id = nft_id
    this.chain = chain
    this.getdetail()
  },
  computed: {
    ...mapState(["chainId","config","language"]),
  },
  mounted() {},
  methods: {
    getdetail(){
      this.$http.get('/collections/collectionDetails',{
        nft_id:this.nft_id,
        chainType:this.chain,
        language: this.language
      }).then(res=>{
        this.collection_id = res.data.collection_id
        this.collectionname = res.data.collectionInfo.collectionname
        this.title = res.data.info.title
        this.tokenId = res.data.info.tokenId
        this.img = res.data.info.image
        this.type = res.data.type
        this.chainType = this.chain
        this.amount = res.data.amount
      })
    },
    async upshop(){
      try {
        this.isloading = true
        let arg = {
          nft_id:this.nft_id,
          price:this.price,
          number:this.num,
          language:this.language
        }
        const res = await this.$http.post('/order/addOrder',arg)
        const {price,number,tokenid,category,contract,address,orderno} = res.data
        const pay = (price * number).toString
        const result = await this.checkAllowance(pay,category,contract)
        console.log(result);
        this.shardsCollect(contract,tokenid,number,address,price,orderno)

        // const rest = await this.sendHash(hash,orderno)
        // this.type == 0 ? await this.$http.post('/collections/userAsset721') : await this.$http.post('/collections/userAsset1155')
        // this.$http.post('/order/pendingOrder')
				// this.isloading = false
				// this.$toast(rest.msg)
        // setTimeout(() => {
        //   this.$router.go(-2)
        // }, 1000);
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async checkAllowance(pay,category,contract){
      const user_address = this.$local.get('shop:account');
      console.log(user_address);
			const web3 = new this.Web3(ethereum);
			// let f_max_num = new BigNumber(2).pow(256).minus(1);  //最大值
			// const max_num = f_max_num.toString(16)
			// console.log(max_num);
			const contract_from = contract; // 被授权的合约地址
			const abi_from = category == 1 ? JSON.parse(this.config.BEP1155) :  JSON.parse(this.config.BEP721);
			const contract_to = this.chainId == "0x2d20"
          ? this.config.omp_market_contract
          : this.chainId == "0x38"
          ? this.config.bsc_market_contract
          : this.chainId == "0x1"
          ? this.config.eth_market
          : this.chainId == "0x89"
          ? this.config.pol_market
          : ''; // 授权的合约地址
			
			const contract_proto = new web3.eth.Contract(abi_from, contract_from,{from:user_address});
			const isAllowance = await contract_proto.methods.isApprovedForAll(user_address,contract_to).call();
			console.log(isAllowance);
			if(isAllowance){
				return true
			} else{
				const result1 = await contract_proto.methods.setApprovalForAll(contract_to,true).send()
				console.log(result1);
        return true
			}
		},
    shardsCollect(contract,tokenid,number,address,price,orderno){
			const web3 = new this.Web3(ethereum);
      const contract_to = this.chainId == "0x2d20"
          ? this.config.omp_market_contract
          : this.chainId == "0x38"
          ? this.config.bsc_market_contract
          : this.chainId == "0x1"
          ? this.config.eth_market
          : this.chainId == "0x89"
          ? this.config.pol_market
          : '';
      const abi_to = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_market_abi) : JSON.parse(this.config.bsc_market_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      console.log(web3.utils.toWei(price,this.chainId == "0x89" || this.chainId == "0x1"?'mwei':'ether'));
      nft_contract.methods.createAskOrder(contract,tokenid,Number(number),web3.utils.toWei(price,this.chainId == "0x89" || this.chainId == "0x1"?'mwei':'ether')).send()
      .on("transactionHash", async (hash) => {
        const rest = await this.sendHash(hash,orderno)
        this.tmsg = "";
        this.tmsg = rest.msg;
      })
      .on("receipt", async (receipt) => {
        console.log(receipt);
        this.type == 0 ? await this.$http.post('/collections/userAsset721') : await this.$http.post('/collections/userAsset1155')
        this.$http.post('/order/pendingOrder')
        this.isloading = false;
        this.$toast(this.tmsg);
        setTimeout(() => {
          this.$router.go(-2)
        }, 1000);
      })
      .on("error", (error, receipt) => {
        console.log(error, receipt);
        this.isloading = false;
      })
		},
    async sendHash(hash,orderno){
			const arg = {
				hash,
				orderno,
				language:this.language
			}
			const rest = await this.$http.post('/order/addHash',arg)
      return rest
		},
    back(){
      this.$router.go(-1)
    },
  },
};
</script>

<style lang="less">
.upinfo-style {
  .box1a {
    background: #f4f4f4;
    padding: 20px;
    margin-bottom: 30px;
    .box1-i1 {
      width: 50px;
      height: 50px;
      margin: 0 16px 0 20px;
    }
    .box1-b1 {
      color: #0f0d0c;
      font-weight: bold;
      .box1-b1-t1 {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .box1-b1-t2 {
        font-size: 18px;
      }
    }
  }
  .box2 {
    width: 335px;
    margin: 0 auto 50px;
    .box2-t1 {
      font-size: 12px;
      font-weight: bold;
      color: #0f0d0c;
      margin-bottom: 9px;
    }
    .box2-b1 {
      height: 50px;
      background: #ffffff;
      border: 1px solid #909090;
      border-radius: 10px;
      margin-bottom: 10px;
      .box2-b1-l {
        width: 30%;
        height: 100%;
        border-right: 1px solid #909090;
        .box2-b1-l-i1 {
          width: 13px;
          // height: 13px;
          margin-right: 10px;
        }
        .box2-b1-l-t1 {
          font-size: 12px;
          font-weight: bold;
          color: #0f0d0c;
        }
      }
      .box2-b1-r {
        flex: 1;
        padding: 0 20px;
        .box2-b1-r-t1 {
          width: 100%;
          font-size: 14px;
          color: #0f0d0c;
          border: none;
        }
      }
    }
    .box2-b2 {
      padding: 0 20px 20px;
      font-size: 12px;
      font-weight: 500;
      color: #909090;
      text-align: right;
    }
    .box2-b3 {
      font-size: 12px;
      font-weight: bold;
      color: #0f0d0c;
    }
    .box2-b3-mb15 {
      margin-bottom: 10px;
    }
    .box2-b3-mb24 {
      margin-bottom: 24px;
    }
    .box2-b4 {
      width: 300px;
      height: 45px;
      background: #323232;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      margin: auto;
    }
  }
}
</style>